import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import './calendar-pl.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';

const firstDay = new Date($('#datepicker-winners').data('start'));

const calendar = $('#datepicker-winners').datepicker({
	clearBtn: false,
	language: "pl",
	maxViewMode: 0,
	startDate: new Date($('#datepicker-winners').data('start')),
	endDate: new Date($('#datepicker-winners').data('end')),
	defaultViewDate: {
		year: firstDay.getFullYear(),
		month: firstDay.getMonth(),
		day: firstDay.getDate()
	}
}).datepicker('setDate', firstDay);

calendar.on('changeDate', function (e) {

	$('section#winners ul[data-id=city]').empty();
	$('section#winners ul[data-id=technic]').empty();
	$('section#winners ul[data-id=friends]').empty();

	let date = new Date(e.date);
	let formattedDate = date.toLocaleDateString('pl-PL', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).replace(/\./g, '-');
	formattedDate = formattedDate.split('-').join('.');

    $('.s-winners-body .selected-date').text(formattedDate);

	$.ajax({
		url: '/winners',
		type: 'GET',
		data: {
			date: formattedDate
		},
		success: function (data) {
            if (data.winners.length > 0) {
                let winners = '';
                data.winners.forEach(function (winner) {
                    winners += `${winner.name} <br/>`;
                });

                $('.instant-winner-data').html(winners);
            } else {
                $('.instant-winner-data').text('Dane Zwycięzcy zostaną opublikowane po weryfikacji Zwycięzców.');
            }
		},
		error: function (data) {
			console.log(data);
		}
	});
});
